import {useIntl} from "react-intl";
import {IMenuContainer} from "../../../../../app/core/interfaces/IMenuItem.ts";
import ERoutes from "../../../../../app/core/enums/ERoutes.ts";

export default function (): IMenuContainer[] {
  const intl = useIntl()

  return [
    {
      name: intl.formatMessage({id: 'MENU.COMPANY'}),
      soon: false,
      justForCompany: true,
      menu: [
        {
          name: intl.formatMessage({id: 'MENU.STATEMENTS'}),
          icon: 'home',
          redirectTo: ERoutes.STATEMENTS,
          soon: false,
          submenu: [],
        },
      ].sort((a, b) => a.soon === b.soon ? 0 : a.soon ? 1 : -1)
    },
    {
      name: intl.formatMessage({ id: 'MENU.SUPER_ADMIN' }),
      soon: false,
      justForSuperAdmin: true,
      menu: [
        {
          name: intl.formatMessage({id: 'MENU.USERS'}),
          icon: 'profile-user',
          redirectTo: ERoutes.USERS,
          hide: false,
          submenu: []
        },
      ]
    }
  ]
}
