import {Dispatch} from 'redux';
import {IResponse} from './Request';
import EHttpStatusCode from "../enums/EHttpStatusCodes";
import {logout} from "../store/slices/sessionSlice";
import updateTokenRequest from './requests/auth/updateTokenRequest';
import { getSession, saveSession } from '../utils/sessionStorage';

export default async function ErrorManager(
  dispatch: Dispatch,
  response: IResponse,
): Promise<IResponse> {
  switch (response.statusCode) {
    case EHttpStatusCode.BAD_REQUEST:
      break;
    case EHttpStatusCode.UNAUTHORIZED:
      const session = getSession()
      const refreshToken = session?.refreshToken

      if (!refreshToken) {
        dispatch(logout())
        window.location.reload()
        break
      }

      const rsp = await updateTokenRequest(dispatch, {refreshToken})
      if (!rsp) {
        dispatch(logout())
        window.location.reload()
        break
      }

      saveSession(rsp.data)
      break;
    default:
      return response
  }

  return response;
}
