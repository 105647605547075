import { Dispatch } from "redux";
import { RACARequest } from "../../Request";
import { endLoading, startLoading } from "../../../store/slices/loadingSlice";
import EHttpStatusCode from "../../../enums/EHttpStatusCodes";
import { logout } from "../../../store/slices/sessionSlice";

export interface IUpdateTokenRequest{
    refreshToken: string
}
export default async function updateTokenRequest(
    dispatch:Dispatch,
    data: IUpdateTokenRequest
){
    dispatch(startLoading())

    const request = new RACARequest('auth', 'updateToken')
    .setData(data)

    const response = await request.makeRequest();

    dispatch(endLoading())

    if (response.statusCode === EHttpStatusCode.OK) {
        return response
    }

    dispatch(logout())
    window.location.reload()
}
