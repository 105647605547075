import {useDispatch} from "react-redux";
import * as Yup from "yup";
import {useIntl} from "react-intl";
import {useFormik} from "formik";
import clsx from "clsx";
import { useState } from "react";
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import { useEffect } from "react";
import passwordRecoveryVerificationRequest, {IPasswordRecoveryVerificationRequest} from "../../../core/api/requests/auth/passwordRecoveryVerificationRequest";
import { useNavigate } from "react-router-dom";
import ERoutes from "../../../core/enums/ERoutes";
import EHttpStatusCodes from "../../../core/enums/EHttpStatusCodes";
import sweetalert2 from 'sweetalert2'

const initialValues = {
  password: '',
  passwordRepeat: '',
}

export default function () {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const intl = useIntl()

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(3, intl.formatMessage({ id: 'VALIDATION.MIN_3_CHARS' }))
      .max(50, intl.formatMessage({ id: 'VALIDATION.MAX_50_CHARS' }))
      .required(intl.formatMessage({ id: 'VALIDATION.COMMON_REQUIRED' })),
    passwordRepeat: Yup.string()
      .min(3, intl.formatMessage({ id: 'VALIDATION.MIN_3_CHARS' }))
      .max(50, intl.formatMessage({ id: 'VALIDATION.MAX_50_CHARS' }))
      .required(intl.formatMessage({ id: 'VALIDATION.COMMON_REQUIRED' }))
      .oneOf([Yup.ref('password')], intl.formatMessage({ id: 'VALIDATION.PASSWORDS_MUST_MATCH' }))
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    onSubmit: async (values, { setSubmitting }) => {

      const queryParams = new URLSearchParams(window.location.search);
      const token = queryParams.get("t");

      if (!token){
        navigate(ERoutes.AUTH) 
        return
      } 
      
      const requestParams:IPasswordRecoveryVerificationRequest = {
          token,
          password: values.password
      }  
      const response = await passwordRecoveryVerificationRequest(dispatch, requestParams)

      if (response.statusCode === EHttpStatusCodes.OK) {
        sweetalert2.fire(
          intl.formatMessage({ id: 'SWAL.EXCELLENT' }),
          intl.formatMessage({ id: 'SWAL.PASSWORD_RECOVERY_SUCCESSFUL' }),
          'success',
        ).then(() => {
          navigate(ERoutes.AUTH)
        })
      } else {
        sweetalert2.fire(
          `Error`,
          intl.formatMessage({ id: response.data.code }),
          'error',
        ).then(() => {
          navigate(ERoutes.AUTH_PASSWORD_RECOVERY_REQUEST)
        })
      }
    },
  })
  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='text-gray-900 fw-bolder mb-3'>{intl.formatMessage({ id: 'TITLES.PASSWORD_CHANGE' })}</h1>
        <p className='fs-6'>{intl.formatMessage({ id: 'PLACEHOLDERS.PASSWORD_CHANGE_MESSAGE' })}</p>
      </div>
      {/* begin::Heading */}

      {/* begin::Form group Password */}
      <div className='fv-row mb-8' data-kt-password-meter='true'>
        <div className='mb-1'>
          <div className='position-relative mb-3'>
            <input
              type='password'
              placeholder={intl.formatMessage({ id: 'PLACEHOLDERS.PASSWORD' })}
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          {/* begin::Meter */}
          <div
            className='d-flex align-items-center mb-3'
            data-kt-password-meter-control='highlight'
          >
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
          </div>
          {/* end::Meter */}
        </div>
        <div className='text-muted'>
          {intl.formatMessage({ id: 'COMMON.PASSWORD_NEEDS' })}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className='fv-row mb-5'>
        <input
          type='password'
          placeholder={intl.formatMessage({ id: 'PLACEHOLDERS.PASSWORD_REPEAT' })}
          autoComplete='off'
          {...formik.getFieldProps('passwordRepeat')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.passwordRepeat && formik.errors.passwordRepeat,
            },
            {
              'is-valid': formik.touched.passwordRepeat && !formik.errors.passwordRepeat,
            }
          )}
        />
        {formik.touched.passwordRepeat && formik.errors.passwordRepeat && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.passwordRepeat}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}


      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>
            {intl.formatMessage({ id: 'COMMON.CONTINUE' })}
          </span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              {intl.formatMessage({ id: 'COMMON.PLEASE_WAIT' })}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </form>
  )
}
