import {useDispatch} from "react-redux";
import {Link} from "react-router-dom";
import {useLocation} from "react-router";
import {useEffect, useState} from "react";
import accountActivationRequest from "../../../core/api/requests/auth/accountActivationRequest.ts";
import EHttpStatusCodes from "../../../core/enums/EHttpStatusCodes.ts";
import ERoutes from "../../../core/enums/ERoutes.ts";
import { useIntl } from "react-intl";
import { saveSession } from "../../../core/utils/sessionStorage.ts";
import { useSelector } from "react-redux";
import { RootState } from "../../../core/store/store.ts";

export default function () {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { search } = useLocation();
  const [activation, setActivation] = useState(false)
  const [error, setError] = useState('')

  const loading = useSelector((state: RootState) => state.loading)

  const request = async () => {
    const response = await accountActivationRequest(
      dispatch,
      {
        token: new URLSearchParams(search).get('t') || '',
      }
    );

    if (response.statusCode === EHttpStatusCodes.OK) {
      setActivation(true)
      saveSession(response.data)
    } else {
      setActivation(false)
      setError(response.data.message || '')
    }
  }

  useEffect(() => {
    request();
  }, [])

  return <div className="d-flex flex-column text-center">
    {!loading && 
      <>
        <div className="mb-0 text-center">
          {activation ? 
            <>
              <img src="/media/auth/ok.png" className="mw-100 mh-300px theme-light-show" alt="" />
              <img src="/media/auth/ok-dark.png" className="mw-100 mh-300px theme-dark-show" alt=""/>
            </> : 
            <>
              <img src="/media/auth/404-error.png" className="mw-100 mh-300px theme-light-show" alt="" />
              <img src="/media/auth/404-error-dark.png" className="mw-100 mh-300px theme-dark-show" alt=""/>
            </>
            }
        </div>    
        <h1 className="fw-bolder text-gray-900 mb-5">
          {activation ? intl.formatMessage({ id: 'AUTH.ACCOUNT_ACTIVATION_SUCCESS' }) : intl.formatMessage({ id: 'AUTH.ACCOUNT_ACTIVATION_ERROR' })}
        </h1>

        <div className="fw-semibold fs-6 text-gray-500 mb-8">
          {activation ? intl.formatMessage({ id: 'AUTH.ACCOUNT_ACTIVATION_SUCCESS_MESSAGE' }) : `${intl.formatMessage({ id: 'AUTH.ACCOUNT_ACTIVATION_ERROR_MESSAGE' })} ${error}`}
        </div>

        <div className="mb-11">
          {activation && 
            <Link to={ERoutes.AUTH} className="btn btn-sm btn-primary">{intl.formatMessage({ id: 'PLACEHOLDERS.LOGIN_NOW' })}</Link>
          }
        </div>    
      </>
    }

  </div>
}
