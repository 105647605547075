import {IResponse, RACARequest} from "../../Request";
import {Dispatch} from "redux";
import {endLoading, startLoading} from "../../../store/slices/loadingSlice";

export interface IAccountActivationRequest {
  token: string;
}

export default async function(
  dispatch: Dispatch,
  input: IAccountActivationRequest,
): Promise<IResponse> {
  dispatch(startLoading())

  const request = new RACARequest('auth', 'account-activation')
    .setData(input);

  const response = await request.makeRequest()

  dispatch(endLoading())
  return response;
}
