import IFilterSection from "../interfaces/IFilterSection";
import {Dispatch} from "redux";
import getFrontendUtilitiesRequest from "../api/requests/utils/getFrontUtilitiesRequest";
import EFilterOptionType from "../enums/EFilterOptionType";
import convertToFraction from "./convertToFraction";

export default async function(
  dispatch: Dispatch,
): Promise<IFilterSection[]> {
  const futils = await getFrontendUtilitiesRequest(dispatch);

  return [
  ]
}
