import {useEffect} from 'react'
import {Navigate, Routes} from 'react-router-dom'
import {useDispatch} from "react-redux";
import {logout} from "../../core/store/slices/sessionSlice.ts";
import ERoutes from "../../core/enums/ERoutes.ts";

export function Logout() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(logout())
    document.location.reload()
  }, [logout])

  return (
    <Routes>
      <Navigate to={ERoutes.AUTH} />
    </Routes>
  )
}
