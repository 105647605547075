import { Dispatch } from "redux";
import { IResponse } from "../../Request";
import { RACARequest } from "../../Request";
import { startLoading, endLoading } from "../../../store/slices/loadingSlice";


export interface ILogoutRequest {
    closeAllSessions: boolean
}

export default async function logoutRequest(
    dispatch: Dispatch,
    data: ILogoutRequest
): Promise<IResponse> {

    dispatch(startLoading());

    const request = new RACARequest('auth', 'logout')
        .setData(data);

    const response = await request.makeRequest();

    dispatch(endLoading());

    return response;
}
