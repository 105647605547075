
import {useEffect} from 'react'
import {Outlet, Link} from 'react-router-dom'
import {useIntl} from "react-intl";
import Logo from '../../utils/Logo';

const AuthCenteredLayout = () => {
  const intl = useIntl()

  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
<div id="auth-centered" className="d-flex flex-column flex-center flex-column-fluid h-100">
    <div className="d-flex flex-column flex-center text-center p-10">
      <div className="card card-flush w-lg-650px py-5">
        <div className="card-body py-15 py-lg-20">
          <div className="mb-13">
            <a href="index.html" className="">
              <Logo />
            </a>
          </div>
          <Outlet />
        </div>
      </div>
    </div>
  </div>
  )
}

export {AuthCenteredLayout}
