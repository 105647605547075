import {Navigate, Route, Routes} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {AuthLayout} from './AuthLayout'
import { AuthCenteredLayout } from './AuthCenteredLayout.tsx'
import PasswordChange from "./components/PasswordChange.tsx";
import AccountActivation from "./components/AccountActivation.tsx";
import ValidateAccount from './components/ValidateAccount.tsx'
import GoogleSignUpHandler from './components/GoogleSignUpHandler.tsx'
import GoogleLoginHandler from './components/GoogleLoginHandler.tsx'
import ERoutes from "../../core/enums/ERoutes.ts";

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>

      <Route path='signup' element={<Registration />} />

      <Route path='forgot-password' element={<ForgotPassword />} />

      <Route path='password-recovery' element={<PasswordChange />} />

      <Route path='activate-account' element={<AccountActivation />} />

      <Route path='oauth/google/signup' element={<GoogleSignUpHandler />} />

      <Route path='oauth/google/login' element={<GoogleLoginHandler />} />

      <Route index element={<Login />} />

      <Route path='*' element={<Navigate to={ERoutes.AUTH} />} />
    </Route>

    <Route element={<AuthCenteredLayout />}>
      <Route path='validate-account' element={<ValidateAccount />} />
    </Route>

  </Routes>
)

export {AuthPage}
