import { Dispatch } from "redux";
import { IResponse, RACARequest } from "../../Request";
import { startLoading, endLoading } from "../../../store/slices/loadingSlice";
import requestMiddlewares from "../../requestMiddlewares";
import { setSession } from "../../../store/slices/sessionSlice";


export default async function googleCredentialsRequest(
    dispatch:Dispatch,
    params:any
): Promise<IResponse>{
    dispatch(startLoading())

    const request = new RACARequest('auth', 'googleCredentials')
    .setQueryParameters(params)

    const response = await request.makeRequest()

    requestMiddlewares(dispatch, response)

    dispatch(endLoading())

    return response

}