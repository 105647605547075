import { Dispatch } from "redux";
import { IResponse, RACARequest } from "../../Request";
import { startLoading, endLoading } from "../../../store/slices/loadingSlice";
import requestMiddlewares from "../../requestMiddlewares";
import { setSession } from "../../../store/slices/sessionSlice";

export interface ISignUpRequest{
    code: string
    returnURL: string
}

export default async function googleSignUpRequest(
    dispatch:Dispatch,
    bodyParams:any
): Promise<IResponse>{

    dispatch(startLoading())

    const request = new RACARequest('auth', 'googleSignUp')
    .setData(bodyParams)

    const response =  await request.makeRequest()

    requestMiddlewares(dispatch, response)
    
    if (response.data) {
        dispatch(setSession(response.data))
    }

    dispatch(endLoading())

    return response

}