import {useDispatch} from "react-redux";
import {Link} from "react-router-dom";
import {useLocation} from "react-router";
import {useEffect, useState} from "react";
import accountActivationRequest from "../../../core/api/requests/auth/accountActivationRequest.ts";
import EHttpStatusCodes from "../../../core/enums/EHttpStatusCodes.ts";
import ERoutes from "../../../core/enums/ERoutes.ts";
import { useIntl } from "react-intl";

export default function () {
  const dipatch = useDispatch();
  const intl = useIntl();
  const { search } = useLocation();
  const [activation, setActivation] = useState(false)
  const [error, setError] = useState('')

  const request = async () => {
    const response = await accountActivationRequest(
      dipatch,
      {
        token: new URLSearchParams(search).get('t') || '',
      }
    );

    if (response.statusCode === EHttpStatusCodes.ACCEPTED) {
      setActivation(true)
    } else {
      setActivation(false)
      setError(response.data.message || '')
    }
  }

  useEffect(() => {
    request();
  }, [])

  return<>
    <h1 className="fw-bolder text-gray-900 mb-5">{intl.formatMessage({ id: 'TITLES.VALIDATE_ACCOUNT' })}</h1>
    
    <div className="fs-6 mb-8">
        <span className="fw-semibold text-gray-500">{intl.formatMessage({ id: 'PLACEHOLDERS.VALIDATE_ACCOUNT_TEXT' })} </span>

        <Link to={ERoutes.AUTH_SIGNUP} className="link-primary fw-bold">{intl.formatMessage({ id: 'PLACEHOLDERS.TRY_AGAIN' })}</Link>
    </div>
    
    <div className="mb-11">
        <Link to={ERoutes.AUTH} className="btn btn-sm btn-primary">{intl.formatMessage({ id: 'PLACEHOLDERS.SKIP_FOR_NOW' })}</Link>
    </div>    
    
    <div className="mb-0">
        <img src="/media/auth/please-verify-your-email.png" className="mw-100 mh-300px theme-light-show" alt="" />
        <img src="/media/auth/please-verify-your-email-dark.png" className="mw-100 mh-300px theme-dark-show" alt="" />
    </div>
  </>
}
