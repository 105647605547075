import {Dispatch} from "redux";
import {IResponse, RACARequest} from "../../Request";
import requestMiddlewares from "../../requestMiddlewares";
import {getSession} from "../../../utils/sessionStorage";
import IReducedAgency from "../../../interfaces/reduced/IReducedAgency";
import IPager from "../../../interfaces/IPager";
import {selectCompany, setAll} from "../../../store/slices/companies";

export default async function (
  dispatch: Dispatch,
): Promise<IResponse<IPager<IReducedAgency>>> {
  const session = getSession()
  if (!session?.token) throw new Error('session has not defined');

  const request = new RACARequest('me', 'myCompanies');

  const response = await request.makeRequest();
  requestMiddlewares(
    dispatch,
    response,
  );

  if (response.data) {
    dispatch(setAll(response.data));

    if (response.data.length === 0) return response;

    const first = response.data[0];
    dispatch(selectCompany(first));
  }

  return response;
}
